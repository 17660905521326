import Image from 'next/image';

import { ConditionalWrapper } from '@/hoc';
import { NextLink } from '@/atoms';

import FallBackIMG from '@/assets/images/fallback-product-card.jpg';
import Prose from '../prose';

import type { TypeCardAccessoryProps } from './types';

/**
 * CardAccessory
 */
export const CardAccessory = ({
  className = '',
  header,
  image,
  title,
  description,
  imageMaxSize,
  imageLink,
  type,
  extraButton,
}: TypeCardAccessoryProps) => (
  <div
    className={`mx-auto flex h-full flex-1 flex-col gap-4 ${className}`}
    {...(type === 'marca'
      ? {
          style: {
            maxWidth: imageMaxSize ?? 255,
          },
        }
      : {})}
  >
    {header && <p className="u-headline u-headline--h2 pl-2">{header}</p>}

    <ConditionalWrapper
      condition={Boolean(imageLink)}
      wrapper={(children) => (
        <NextLink href={imageLink ?? '/'}>
          <a aria-label={title ?? ''}>{children}</a>
        </NextLink>
      )}
    >
      <Image
        src={image ? image.sourceUrl : FallBackIMG.src}
        alt={image?.altText ?? ''}
        draggable={false}
        width={image ? image.mediaDetails.width : FallBackIMG.width}
        height={image ? image.mediaDetails.height : FallBackIMG.height}
        className="mx-auto w-full object-cover"
        sizes="(max-width: 1024px) 50vw, 25vw"
      />
    </ConditionalWrapper>

    {title && (
      <span className="u-subtitle u-subtitle--s flex grow items-start self-center px-2 text-center">
        {title}
      </span>
    )}

    {description && <Prose as="div" className="pl-2" html={description} />}

    {extraButton && (
      <div className="flex justify-center">
        {typeof extraButton === 'function' ? extraButton() : extraButton}
      </div>
    )}
  </div>
);
